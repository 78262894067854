import React from 'react';
import App from 'next/app';
import Router from 'next/router';

import { Provider } from 'react-redux';
import withRedux from 'next-redux-wrapper';
import withReduxSaga from 'next-redux-saga';

import TagManager from 'react-gtm-module';

import styled from 'styled-components';

import { BaseStyles } from '@pik/pik-ui';

import makeStore from '../services/store';

import ResponsiveContainer from '../components/ResponsiveContainer';

export const Wrapper = styled.div`
  min-height: 80vh;
`;

export const ComponentWrapper = styled.div`
  height: 100%;
`;

class PikElementsLanding extends App {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};
    const error = null;

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return { pageProps, error };
  }

  componentDidMount() {
    const isProd = process.env.NODE_ENV === 'production';

    Router.onRouteChangeStart = () => window.scrollTo(0, 0);

    if (isProd) {
      TagManager.initialize({ gtmId: 'GTM-TB9RWMD' });
    }
  }

  render() {
    const {
      store, Component, pageProps, error,
    } = this.props;

    if (error) {
      return '404 NOT FOUND';
    }

    return (
      <Provider store={store}>
        <BaseStyles />
        <Wrapper id="AppWrapper">
          <ComponentWrapper>
            <Component {...pageProps} />
          </ComponentWrapper>
        </Wrapper>
        <ResponsiveContainer />
      </Provider>
    );
  }
}

export default withRedux(makeStore)(withReduxSaga(PikElementsLanding));
