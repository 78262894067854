import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ResponsiveIndicator } from '@pik/pik-ui';

import { changeScreenType } from '../../services/app/actions';

const ResponsiveContainer = ({ changeScreenTypeAction }) => (
  <ResponsiveIndicator onChange={type => changeScreenTypeAction(type)} />
);

const mapDispatchToProps = {
  changeScreenTypeAction: changeScreenType,
};

ResponsiveContainer.propTypes = {
  changeScreenTypeAction: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(ResponsiveContainer);
